<template>
  <div class="main-box">
    <el-tabs type="card" @tab-click="changeType" v-model="menuType">
      <el-tab-pane label="门店订货月份数据" name="1"></el-tab-pane>
      <el-tab-pane label="筛选条件(月)" name="2"></el-tab-pane>
      <el-tab-pane label="筛选条件(日)" name="3"></el-tab-pane>
    </el-tabs>
    <div v-show="menuType==1">
      <el-table
          :data="tableData"
          ref="rfTable"
          border
          size="medium">
        <el-table-column
            prop="title"
            label="门店"
            align="center"
            fixed
            width="130">
        </el-table-column>
        <el-table-column
            v-for="(item,index) in tableHeader"
            :key="index"
            :prop="item"
            :label="item"
            align="center"
            width="140">
        </el-table-column>
      </el-table>
      <div class="title mg-tp-30">门店订货增量曲线图</div>
      <div class="echart" id="mychart" :style="myChartStyle"></div>
    </div>
    <div v-show="menuType==2">
      <el-form class="form-inline" :inline="true">
        <el-form-item label="">
          <el-date-picker
              v-model="search.month"
              type="month"
              size="small"
              value-format="yyyy-MM"
              placeholder="选择月">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getMonthData(0)">搜索</el-button>
          <el-button type="warning" size="small" @click="getMonthData(1)">导出</el-button>
        </el-form-item>
      </el-form>
      <h2 class="mg-bt-20" v-if="monthData">
        <span class="mg-rt-10">门店总订单：{{monthData.count.sum}}个，</span>
        <span>总金额：￥{{monthData.count.amount}}</span>
      </h2>
      <el-table v-if="monthData"
          :data="monthData.data"
          ref="rfTable"
          border
          size="medium">
        <el-table-column
            prop="title"
            label="门店"
            align="center"
            fixed
            width="130">
        </el-table-column>
        <el-table-column
            prop="sum"
            label="下单数"
            align="center"
            fixed
            width="130">
        </el-table-column>
        <el-table-column
            prop="amount"
            label="下单金额"
            align="center"
            fixed
            width="130">
        </el-table-column>
      </el-table>
    </div>
    <div v-show="menuType==3">
      <el-form class="form-inline" :inline="true">
        <el-form-item label="">
          <el-date-picker
              v-model="search.day"
              type="date"
              size="small"
              value-format="yyyy-MM-dd"
              placeholder="选择日">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getDayData(0)">搜索</el-button>
          <el-button type="warning" size="small" @click="getDayData(1)">导出</el-button>
        </el-form-item>
      </el-form>
      <h2 class="mg-bt-20" v-if="dayData">
        <span class="mg-rt-10">门店总订单：{{dayData.count.sum}}个，</span>
        <span>总金额：￥{{dayData.count.amount}}</span>
      </h2>
      <el-table v-if="dayData"
            :data="dayData.data"
            ref="rfTable"
            border
            size="medium">
        <el-table-column
            prop="title"
            label="门店"
            align="center"
            fixed
            width="130">
        </el-table-column>
        <el-table-column
            prop="sum"
            label="下单数"
            align="center"
            fixed
            width="130">
        </el-table-column>
        <el-table-column
            prop="amount"
            label="下单金额"
            align="center"
            fixed
            width="130">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>

import * as echarts from "echarts";
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      tableData: [],
      tableHeader: [],
      storeData: [],
      zengData: [],
      myChart: false,
      myChartStyle: { float: "left", width: "70%", height: "400px" },
      menuType: '1',
      search: {
        month: '',
        day: ''
      },
      monthData: false,
      dayData: false
    };
  },
  created() {
    var date = new Date();
    this.search.month = date.getFullYear() + '-' + (date.getMonth() + 1);
    this.search.day = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    changeType(e) {
      this.menuType = e.name
      if(this.menuType==2 && !this.monthData) {
        this.getMonthData(0)
      }
      if(this.menuType==3 && !this.dayData) {
        this.getDayData(0)
      }
    },
    getList() {
      this.showLoading()
      this.$api.statics.staticsOrderGoods({}, res => {
        this.hideLoading()
        if (res.errcode == 0) {
          this.tableData = res.data.data
          this.tableHeader = res.data.months
          this.storeData = res.data.store
          this.zengData = res.data.zeng
          this.initEcharts();
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getMonthData(type) {
      this.showLoading()
      this.$api.statics.staticsMonth({date: this.search.month,is_export:type}, res => {
        this.hideLoading()
        if (res.errcode == 0) {
          if(type==0) {
            this.monthData = res.data
          } else {
            this.downFile(res.data.url,this.search.month + '.xlsx')
          }
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getDayData(type) {
      this.showLoading()
      this.$api.statics.staticsDay({date: this.search.day,is_export:type}, res => {
        this.hideLoading()
        if (res.errcode == 0) {
          if(type==0) {
            this.dayData = res.data
          } else {
            this.downFile(res.data.url,this.search.day + '.xlsx')
          }
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    downFile(url,fileName) {
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob'
      }).then(response => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
    },
    initEcharts() {
      const option = {
        xAxis: {
          data: this.tableHeader
        },
        legend: { // 图例
          data: this.storeData,
          bottom: "0%"
        },
        yAxis: {},
        series: [
          // {
          //   name: "人数",
          //   data: this.yData,
          //   type: "line", // 类型设置为折线图
          //   label: {
          //     show: true,
          //     position: "top",
          //     textStyle: {
          //       fontSize: 16
          //     }
          //   }
          // }
        ]
      };
      if(this.zengData) {
        for(let key in this.zengData) {
          var series = {
            name: key,
            data: this.zengData[key],
            type: "line", // 类型设置为折线图
            label: {
              show: true,
              textStyle: {
                fontSize: 16
              }
            }
          }
          option.series.push(series)
        }
      }
      this.myChart = echarts.init(document.getElementById("mychart"));
      this.myChart.setOption(option);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }
  }
};
</script>
<style scoped>
.gdtable tr:last-child {
  border-bottom: unset !important;
}
.title {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #666;
}
</style>
